@import "/node_modules/antd/dist/antd.less";

.root {
  height: 100%;
  display: flex;
}

.main-content {
  overflow: auto;
  min-height: 0;
  flex: 1 1 0;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
    flex: auto;
}

.ant-table-wrapper {
  overflow: scroll;
}

.statistics-select > .ant-select-selector {
  border: none !important;
  background: none !important;
}

.ant-descriptions-item-label {
  white-space: nowrap;
}
