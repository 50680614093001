.layout {
    width: 100%;
    overflow: scroll;
    background: whitesmoke;
    
    .header {
        width: 100%;
        height: 580px;
        position: relative;
    
        .menu {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50px;
    
            .link {
                font-size: 18px;
                font-weight: bold;
                color: #fff;
                margin: 0 20px;
            }

            .link:hover {
                color: red;
            }

            .current-link {
                font-size: 18px;
                font-weight: bold;
                color: red;
                margin: 0 20px;
            }
        }
    }

    .content {
        position: relative;
        top: -80px;
        padding: 0 20px 40px;
    }
}
